<template>
  <div class="c-alpha-reset-password">
    <div class="c-alpha-reset-password__content">
      <div class="c-alpha-reset-password__content-wrapper">
        <h2 translate="reset_password_title" class="c-alpha-reset-password__content-title"><span class="c-alpha-reset-password__content-title_blue">Reset </span>Password</h2>
        <div id="resetNewPwdGeetest" class="c-alpha-reset-password__content-form">
          <div class="c-alpha-reset-password__content-form-container">
            <div class="c-alpha-reset-password__content-form-container-fg">
              <div class="c-alpha-reset-password__content-form-container-fg-box">
                <input class="c-alpha-reset-password__content-form-container-fg-box-input" type = "password" name="password" id="reset-pass" placeholder = "password"  v-model="input.password" @input="debouncedPasswordCheck" required>
                <div class="eye-reset" v-if="!toggleEye" @click="showEye(true)"><img class="c-alpha-reset-password__content-form-container-fg-box-input-eye" src="@/assets/images/old-alpha/eye-slash.png" alt="Password Hidden"> </div>
                <div class="eye-reset" v-if="toggleEye" @click="showEye(false)"><img class="c-alpha-reset-password__content-form-container-fg-box-input-eye" src="@/assets/images/old-alpha/eye.png" alt="Password Shown"></div>
              </div>
              <div class="c-alpha-reset-password__content-form-container-fg-error" translate="password_validate" v-if="validPassword == false">
                The password must contain at least eight characters and contain uppercase letters, lowercase letters, numbers, and special characters.
              </div>
              <div class="c-alpha-reset-password__content-form-container-fg-error">{{errorText}}</div>
              <span class="error-color"></span>
            </div>
          </div>
          <button class="c-alpha-reset-password__content-form-button" :class="{'c-alpha-reset-password__content-form-button_disable': validPassword == null || validPassword == false}" translate="resend_verification_button" @click="resetPassword">Submit</button>
        </div>
      </div>
    </div>
    <div id="alertModal" class="c-alpha-reset-password__modal" v-if="showAlert">
      <div class="c-alpha-reset-password__modal-alert">
        <div class = "c-alpha-reset-password__modal-alert-header">
          <img src = "@/assets/images/old-alpha/logo.png" class="c-alpha-reset-password__modal-alert-header-img" alt="Sparrow Exchange">
        </div>
        <div class="c-alpha-reset-password__modal-alert-close" id = "alertModalClose">
          <img class="c-alpha-reset-password__modal-alert-close-img" src = "@/assets/images/old-alpha/X-icon-01.png" alt="Close Button">
        </div>
        <div class = "c-alpha-reset-password__modal-body">
          <div class="c-alpha-reset-password__modal-body-content">
            <!-- from js -->
          </div>
          <div class="modal-body-footer">
            <button id="submit-alert" class="c-alpha-reset-password__modal-body-content-button" translate="resend_verification_buttonok">OK</button>
          </div>
        </div>
      </div>
    </div>
    <div id="suspendModal" class="c-alpha-reset-password__modal c-alpha-reset-password__modal_darker" v-if="showSuspend">
      <div class="c-alpha-reset-password__modal-suspend">
        <img src="@/assets/images/old-alpha/alert.png" class="c-alpha-reset-password__modal-suspend-img" alt="Alert">
        <h1 class="c-alpha-reset-password__modal-suspend-title">Account <span class="c-alpha-reset-password__modal-suspend-title_bold">Suspended</span></h1>
        <div class="c-alpha-reset-password__modal-suspend-desc">Your account has been suspended. <br />Please contact support for help.</div>
        <div class="modal-btn">Ok</div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce';
import publicKey from 'raw-loader!../../../public/key/sparrowSecurityAuthPub.key'

export default {
  data() {
    return {
      toggleEye: false,
      showAlert: false,
      showSuspend: false,
      input:{
        password: '',
        t:Date.now()
      },
      validPassword: null,
      forge: require('node-forge'),
      pubKey:null,
      publicKey:publicKey,
      errorText: ''
    }
  },
  mounted() {
    this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
  },
  methods: {
    checkPasswordValidity: function(){
      let upperCase = new RegExp('[A-Z]');
      let numbers = new RegExp('[0-9]');
      let lowerCase = new RegExp('[a-z]');
      let specialChar = new RegExp('[~`!@#$%^&*()+=_{}\\[\\]\\-]');
      var password_length_min = 8;
      this.validPassword = this.input.password!='' && upperCase.test(this.input.password) && numbers.test(this.input.password) && lowerCase.test(this.input.password) && specialChar.test(this.input.password) && this.input.password.length >= password_length_min;
    },
    showEye(param) {
      let passwordElement = document.getElementsByClassName('c-alpha-reset-password__content-form-container-fg-box-input')[0]
      if(param == true) {
        passwordElement.type = 'Text'
      } else {
        passwordElement.type = 'Password'
      }
      this.toggleEye = param
    },
    encryptInput:function(){
      this.input.t = Date.now();
      const enc = this.symEncrypt(this.input);
      const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      return {
        ciphertext: this.forge.util.encode64(enc.ciphertext),
        key: this.forge.util.encode64(key),
        iv: this.forge.util.encode64(iv),
      };
    },
    symEncrypt:function(dt){
      const key = this.forge.random.getBytesSync(32);
      const iv = this.forge.random.getBytesSync(16);
      const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
      encCipher.start(iv);
      encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
      encCipher.finish();
      const ciphertext = encCipher.output.data;
      return {
        key,
        iv,
        ciphertext,
      };
    },
    resetPassword() {
      let _this = this
      if(this.validPassword == true) {
        var encryptedInput = this.encryptInput();
        let url = new URL(window.location.href);
        let searchParams = new URLSearchParams(url.search);
        this.$http.post(this.createApiUrlChestNut("users/reset-password/"+searchParams.get('token')), encryptedInput).then(() =>{
          _this.openLink('/notif-changepwd')
        },err => {
          if(err.body.message == 'UnmetPasswordRequirement') {
            this.errorText = 'Password must contain uppercase and a number'
          } else if(err.body.message == 'RejectReusePassword'){
            this.errorText = 'Old password cannot be used again.'
          } else if(err.body.message == 'Token Has Expired'){
            this.errorText = 'Your request has expired. Please submit a new request again.'
          } else if(err.body.message == 'Password is too long.'){
            this.errorText = 'Your new password has exceeded the maximum password length.'
          } else if(err.body.message == 'Invalid token'){
            this.errorText = 'Your request is invalid. Please submit a new request again.'
          } else {
            this.errorText = err.body.message
          }
        });
      }
    }
  },
  computed: {
    debouncedPasswordCheck: function(){
      return _debounce(this.checkPasswordValidity, 500);
    },
  },
  watch: {

  }
}
</script>
